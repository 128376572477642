import { ToastPosition, toast } from "react-toastify";

interface IOptions {
	toastId?: string | number;
	position?: ToastPosition;
	hideProgressBar?: boolean;
	autoClose?: number | false;
	style?: Record<string, string>;
	className?: string;
	bodyClassName?: string;
	closeOnClick?: boolean;
}

const useToastNotifications = () => {
	const _initialOptions: IOptions = { position: "bottom-right", hideProgressBar: true, autoClose: 2000 };

	const pushToastNotification = (content: string | React.ReactNode, options?: IOptions) =>
		toast(content, { ..._initialOptions, ...options });

	const dismissToastNotification = (id: string) => toast.dismiss(id);

	return { pushToastNotification, dismissToastNotification };
};

export default useToastNotifications;
