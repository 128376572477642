import { Box } from "@material-ui/core";

import styled from "styled-components";

export const ToastWrapper = styled(Box)`
	width: 100%;
	.Toastify__toast {
		background: ${({ theme }) => theme.palette.background.paper} !important;
		padding: 0;
	}
	.Toastify__toast-container:has(div.wide) {
		width: 450px;
		${({ theme }) => theme.breakpoints.down("sm")} {
			width: 100vw;
		}
	}
	.Toastify__close-button {
		color: ${({ theme }) => theme.palette.text.primary} !important;
		position: absolute;
		right: 10px;
		top: 10px;
	}
	.Toastify__toast-body {
		padding: 0 !important;
		cursor: default;
		width: 100%;
	}
`;

export const AnnouncementWrapper = styled(Box)`
	display: flex;
	flex-direction: column;

	.react-multi-carousel-track {
		width: 312px !important;
		li {
			width: 312px !important;
		}
		${({ theme }) => theme.breakpoints.down("sm")} {
			width: 100vw !important;
			li {
				width: 100vw !important;
			}
		}
	}
	.react-multi-carousel-dot-list {
		justify-self: flex-start;
		margin-bottom: 10px;
		margin-left: 25px;
	}
	.react-multiple-carousel__arrow--left {
		left: 10px;
		bottom: 35px;
		right: unset;
		top: unset;
		background: unset;
	}
	.react-multiple-carousel__arrow--right {
		left: 45px;
		bottom: 35px;
		right: unset;
		top: unset;
		background: unset;
	}
	.carousel-item {
		width: 312px;
		${({ theme }) => theme.breakpoints.down("sm")} {
			width: 100vw !important;
		}
	}
	p {
		color: ${({ theme }) => theme.palette.text.primary};
	}

	.body {
		padding-left: 24px;
		padding-right: 24px;
		min-height: 180px;
		word-wrap: break-word;
		p:first-child {
			font-family: var(--poppins);
			font-weight: 700;
			font-size: 18px;
			margin-top: 5px;
			margin-bottom: 5px;
		}
		p:last-child {
			font-family: var(--openSans);
			font-weight: 400;
			font-size: 13px;
		}
	}

	.action {
		display: flex;
		justify-content: flex-end;
		height: 100px;
		align-items: center;
		padding: 10px;
	}
`;

export const PushWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	p {
		color: ${({ theme }) => theme.palette.text.primary};
	}

	.body {
		padding-left: 24px;
		padding-right: 24px;
		p:first-child {
			font-family: var(--openSans);
			font-weight: 600;
			font-size: 15px;
			margin-top: 5px;
			margin-bottom: 5px;
		}
		p:last-child {
			font-family: var(--openSans);
			font-weight: 400;
			font-size: 15px;
			color: ${({ theme }) => theme.palette.text.secondary};
		}
	}
`;
