import { Box } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Box)`
	background-color: ${({ theme }) => theme.palette.background.default};
	min-height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	display: flex;
	flex-direction: column;
`;

export const LeftContainer = styled.nav`
	z-index: 7;
	${({ theme }) => theme.breakpoints.down("sm")} {
		width: 0;
	}
	${({ theme }) => theme.breakpoints.up("sm")} {
		width: 72px;
	}
`;

export const RightContainer = styled.main`
	//height: 100%;
	//width: 100%;
	box-sizing: border-box;
	/* hide left gap if user is logged in */
	padding-left: ${({ loggedIn, isMobile }) => (loggedIn && !isMobile ? "64px" : "0")};
	padding-top: ${({ loggedIn, isMobile, isTrial }) => {
		if (loggedIn && !isMobile && isTrial) return "100px";
		else if (isMobile && isTrial) return "80px";
		else if (isMobile && !isTrial) return "39px";
		return "70px";
	}};
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

export const RightBodyContainer = styled(Box)`
	padding-bottom: 16px;
	padding-left: ${({ isMobile }) => (isMobile ? "0px" : "64px")};
`;
