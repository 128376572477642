import React, { useEffect, useMemo } from "react";

import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTimeLeftCountDown } from "@remar/shared/dist/hooks/useTimeLeftCountDown";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import clsx from "clsx";
import { addMinutes, isAfter, isBefore } from "date-fns";

import { useAppDispatch, useAppSelector } from "store";
import { hideBannerOnCancelSub, selectInactiveSubscription, selectIsTrial } from "store/features/Auth/authSlice";
import { IBannerData } from "store/features/notifications/notifications.model";
import { setBannerData } from "store/features/notifications/notifications.slice";

import { BannerContainer, useStyles } from "./styles";

import { GLOBAL_CONSTANTS } from "../../../constants";
import { getTotalMinutesFromDuration } from "../../../utils/getTotalMinutesFromDuration";

interface ISaleBanner {
	startDate: string;
	endDate: string;
	copy: string;
	background: string;
	isTrial: boolean;
}

type SaleBannerProps = Pick<ISaleBanner, "copy">;
const SaleBanner = ({ copy }: SaleBannerProps) => <Box>{decodeURIComponent(atob(copy))}</Box>;

const Banner = ({ bannerData, timerExpired }: { bannerData: IBannerData; timerExpired?: () => void }) => {
	const {
		bannerMessage,
		duration,
		createdAt,
		expiresAt,
		isDismissible = true,
		countDown,
		action,
		isActionLoading,
		actionButton
	} = bannerData;
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isTrial = useAppSelector(selectIsTrial);
	const trial = bannerData.isTrial ?? isTrial;
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);

	const { onStart, timeLeft, isStarted } = useTimeLeftCountDown();

	useEffect(() => {
		const currentDate = new Date();
		if (duration && createdAt) {
			const totalMinutes: number = getTotalMinutesFromDuration(duration);
			const _expiry = addMinutes(new Date(createdAt), totalMinutes);
			if (isBefore(currentDate, _expiry)) {
				onStart(_expiry);
			}
		}
		if (countDown && expiresAt) {
			if (isBefore(currentDate, new Date(expiresAt))) {
				onStart(new Date(expiresAt));
			}
		}
	}, [duration, countDown, expiresAt, createdAt]);

	useEffect(() => {
		const currentDate = new Date();
		if (duration && createdAt) {
			const totalMinutes: number = getTotalMinutesFromDuration(duration);
			const _expiry = addMinutes(new Date(createdAt), totalMinutes);
			if (isAfter(currentDate, _expiry) && !isStarted) {
				timerExpired && timerExpired();
			}
		}
		if (expiresAt) {
			if (isAfter(currentDate, new Date(expiresAt)) && !isStarted) {
				timerExpired && timerExpired();
			}
		}
	}, [duration, countDown, createdAt, expiresAt, isStarted]);

	const saleBannersToShow = useMemo(() => {
		const { SALE_BANNERS } = GLOBAL_CONSTANTS;
		return SALE_BANNERS
			? (JSON.parse(SALE_BANNERS) as Array<ISaleBanner>).filter(({ endDate, startDate }) => {
					const now = new Date();
					return new Date(startDate) < now && new Date(endDate) > now;
			  })
			: [];
	}, []);

	const shouldShowSalesBanner = useMemo(
		() => trial && !isRenewEnabled && !!saleBannersToShow.length,
		[trial, isRenewEnabled, saleBannersToShow]
	);

	const bannerMessageText = useMemo(() => {
		if (shouldShowSalesBanner) {
			return <SaleBanner {...saleBannersToShow[0]} />;
		}
		return bannerMessage;
	}, [shouldShowSalesBanner, bannerMessage]);

	const showCloseIcon = useMemo(() => isDismissible, [isDismissible]);
	const showCustomColor = trial && saleBannersToShow.length;

	return (
		<>
			<Box
				onClick={() => {
					if (isActionLoading) {
						return;
					}
					action && action();
				}}
				style={{ backgroundColor: showCustomColor ? saleBannersToShow[0].background : "" }}
				className={clsx(classes.defaultNotice, isActionLoading && classes.disableBanner)}
			>
				<Box
					display={"flex"}
					alignItems={"center"}
					flexDirection={isMobile ? "column" : "row"}
					justifyContent="center"
					flexBasis={"90%"}
				>
					<BannerContainer>
						<Typography className={clsx(classes.bannerMessage)}>{bannerMessageText}</Typography>
					</BannerContainer>
					<Typography
						style={{ marginLeft: "10px", fontWeight: "600" }}
						className={clsx(classes.timerText)}
						align={"center"}
					>
						{timeLeft}
					</Typography>
				</Box>
				{isActionLoading || (showCloseIcon && isDismissible) || (actionButton && !shouldShowSalesBanner) ? (
					<Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={isMobile ? "auto" : "150px"}>
						{actionButton && !shouldShowSalesBanner && actionButton}
						{isActionLoading ? (
							<Box width={40} display={"flex"} justifyContent={"center"} alignItems={"center"}>
								<ContentLoader size={"1rem"} height={"30px"} color={"secondary"} />
							</Box>
						) : (
							showCloseIcon &&
							isDismissible && (
								<IconButton
									size="small"
									className={classes.closeNotice}
									onClick={e => {
										dispatch(setBannerData({ showBanner: false }));
										dispatch(hideBannerOnCancelSub());
										e.stopPropagation();
									}}
								>
									<Close />
								</IconButton>
							)
						)}
					</Box>
				) : null}
			</Box>
		</>
	);
};

export default Banner;
