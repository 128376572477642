import React, { useEffect } from "react";

import { Box } from "@material-ui/core";
import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import { EmptyState, Wrapper } from "@remar/shared/dist/layouts";

import { CourseChapterSection, CourseChapterSectionLesson } from "@remar/shared/dist/models";
import { useAppDispatch, useAppSelector } from "store";
import { fetchSearchResult, getFullSearchState } from "store/features/Search/search.slice";

import CourseLessonItem from "../Course/CourseLessonItem";

const Search = () => {
	const dipatch = useAppDispatch();
	const params = useSearchParams();
	const { isLoading, searchResult } = useAppSelector(getFullSearchState);
	const text = params.get("text");

	useEffect(() => {
		if (text) {
			dipatch(fetchSearchResult({ text }));
		}
	}, [text]);

	return (
		<Wrapper heading={`Search result For "${text}"`} showLoader={isLoading}>
			<Box display={"flex"} flexWrap={"wrap"} width={"100%"}>
				{(searchResult || []).map((lesson, i) => (
					<CourseLessonItem
						key={lesson.id}
						number={i + 1}
						sectionLesson={lesson}
						section={{} as CourseChapterSection}
						latestLesson={{} as CourseChapterSectionLesson}
						isSearchResults={true}
					/>
				))}
			</Box>
			{searchResult?.length === 0 && <EmptyState description="No results found" />}
		</Wrapper>
	);
};

export default Search;
