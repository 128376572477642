import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import RecaptchaTerms from "@remar/shared/dist/components/ReCaptchaTerms";
import useCaptchaToken from "@remar/shared/dist/hooks/useVerifyCaptcha";
import { FullScreenWithLogoLayout } from "@remar/shared/dist/layouts";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";

import ReactPixel from "react-facebook-pixel";

import { useHistory, useParams } from "react-router-dom";

import { RootState, useAppDispatch, useAppSelector } from "store";
import {
	changeSubscriptionType,
	getFullState,
	setIsLoading,
	setSubscriptionTypesForSignup,
	setUserSubscriptionTypeId,
	signUp
} from "store/features/Auth/authSlice";

import AuthBackground from "assets/images/signup-background.png";
import { getCallbackUrl } from "modules/utils";

import { AccountFormDetails } from "../components/Forms";
import SignUpSuccessLeft from "../components/SignUpSuccessLeft";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import { SignupSchema } from "../components/schemas";
import { useStyles } from "../components/styles";

const initialFormValues = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
	password: "",
	confirmPassword: "",
	terms: false
};

const TrialSignUp = () => {
	const classes = useStyles();
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useAppDispatch();
	const { handleGenerateCaptchaToken } = useCaptchaToken();
	const { courseId } = useParams<{ courseId?: string }>();
	const history = useHistory();

	const [signUpCompleted, setSignUpCompleted] = useState(false);

	const {
		errorMessage: error,
		isLoading: loading,
		validatedCoupon,
		subscriptionTypes
	}: RootState["auth"] = useAppSelector(getFullState);

	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);

	const activeSubscription = subscriptionTypes[0];

	useEffect(() => {
		ReactPixel.track("PageView");
		return () => {
			dispatch(setUserSubscriptionTypeId(0));
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: courseId as unknown as number,
				isTrial: true
			})
		);
	}, [courseId, dispatch]);

	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
		}
	}, [activeSubscription, dispatch]);

	const handleSubmit = (values: typeof initialFormValues) => {
		const couponCode = validatedCoupon && validatedCoupon.coupon.code;
		dispatch(setIsLoading(true));
		return handleGenerateCaptchaToken()
			.then(token => {
				dispatch(signUp({ elements, stripe, values: { ...values }, recaptchaGoogle: token, couponCode })).then(r => {
					if (!r.error) {
						setSignUpCompleted(true);
						if (callBackUrl) {
							window.location.replace(callBackUrl);
						}
					}
				});
			})
			.catch(() => setIsLoading(false));
	};

	if (signUpCompleted) {
		return (
			<FullScreenWithLogoLayout customBgImage={AuthBackground}>
				<Box display="flex" className={classes.trialSuccessContainer}>
					<Card className={classes.cardLeft}>
						<SignUpSuccessLeft trial={true} />
					</Card>
					<Card className={classes.cardRight}>
						<SignUpSuccessRight />
					</Card>
				</Box>
			</FullScreenWithLogoLayout>
		);
	}

	return (
		<FullScreenWithLogoLayout customBgImage={AuthBackground}>
			<Box maxWidth={570} width="100%" margin="auto">
				<Card className={classes.cardTrial}>
					<Formik
						initialValues={initialFormValues}
						validationSchema={SignupSchema}
						onSubmit={values => {
							handleSubmit(values);
						}}
					>
						{({ isValid, values, touched, setFieldValue, errors }) => {
							const { terms } = values;
							const neverTouched = Object.keys(touched).length === 0;
							const valid = isValid && !neverTouched && terms;

							return (
								<Box px={9} pt={6} pb={4} className={classes.stepperForm}>
									<Form>
										<AccountFormDetails
											title="Trial Sign Up"
											setFieldValue={setFieldValue}
											confirmPassword
											trialSignUp
											phoneNumber={values.phoneNumber}
										/>
									</Form>
									{error && (
										<Box mt={3}>
											<Typography variant="caption" style={{ color: "red" }}>
												{error}
											</Typography>
										</Box>
									)}

									<Box mt={1}>
										<FormControlLabel
											control={
												<Checkbox
													checked={values.terms}
													onChange={() => setFieldValue("terms", !values.terms)}
													color="primary"
													name="terms"
												/>
											}
											label={
												<span className={classes.termsAndConditionsCheck}>
													I agree to the{" "}
													<a target="_blank" rel="noreferrer" href="https://study.remarnurse.com/vit/terms-conditions/">
														<span className={classes.termsLink}>Terms & Conditions</span>
													</a>
												</span>
											}
										/>
										{errors.terms && (
											<>
												<br />
												<Typography variant="caption" style={{ color: "red" }}>
													{errors.terms}
												</Typography>
											</>
										)}
									</Box>
									<Box mt={1} display="flex" justifyContent="flex-end">
										<Button
											size="large"
											color="primary"
											disabled={!valid || loading || !activeSubscription}
											loading={loading}
											variant="filled"
											onClick={() => handleSubmit(values)}
										>
											Create Account
										</Button>
									</Box>
									<Box mt={2}>
										<RecaptchaTerms />
									</Box>
								</Box>
							);
						}}
					</Formik>
				</Card>
			</Box>
		</FullScreenWithLogoLayout>
	);
};

export default TrialSignUp;
