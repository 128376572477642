import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from "react";

import {
	Box,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	MenuItem,
	SvgIcon,
	Theme,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@remar/shared/dist/components/Button";
import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { TextField } from "@remar/shared/dist/components/TextField";
import { usePasswordErrorBox } from "@remar/shared/dist/hooks/usePasswordErrorBox";
import { Country } from "@remar/shared/dist/models";
import { getCountryCode } from "@remar/shared/dist/utils/getCountryCode";
import { validZipCodeCharacter, validatePhoneNumber } from "@remar/shared/dist/utils/serviceUtils/validators";
import { PaymentElement, PaymentMethodMessagingElement, useElements } from "@stripe/react-stripe-js";
import { addMonths, format } from "date-fns";
import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikTouched } from "formik";
import isEmpty from "lodash/isEmpty";
import PhoneInput, {
	Country as PhoneInputCountry,
	getCountryCallingCode,
	isValidPhoneNumber
} from "react-phone-number-input";

import { useAppDispatch, useAppSelector } from "store";
import { IPaymentType } from "store/features/Auth/auth.model";
import { fetchCountries, getFullState, setSelectedCountry } from "store/features/Auth/authSlice";

import PasswordMatchBox from "./PasswordMatchBox";

import {
	CardName,
	ManageText,
	PaymentExpiredText,
	PaymentMethodMessagingElementWrapper,
	PaymentWrapper,
	PhoneWrapper,
	StyledCalenderIcon,
	StyledVisibilityOffOutlined,
	StyledVisibilityOutlined,
	useStyles
} from "./styles";
import { getCardIcon } from "./utils";

import { GLOBAL_CONSTANTS } from "../../../constants";

import "react-phone-number-input/style.css";

const CountrySelect = ({
	inputRef,
	setCountry,
	...props
}: {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	[fieldName: string]: unknown;
	setCountry?: React.Dispatch<React.SetStateAction<Country | undefined>>;
}) => {
	const dispatch = useAppDispatch();
	const classes = useStyles();
	const { countries, isLoading } = useAppSelector(getFullState);

	useEffect(() => {
		if (!countries?.length) {
			dispatch(fetchCountries(0));
		}
	}, [countries?.length, dispatch]);

	return (
		<TextField
			innerRef={inputRef as RefObject<unknown>}
			InputProps={{ disableUnderline: true, ref: inputRef as RefObject<unknown> }}
			inputProps={{ className: classes.countrySelector }}
			variant="filled"
			select
			{...props}
			onChange={e => {
				const value = +e.target.value;
				dispatch(setSelectedCountry(value));
				setCountry && setCountry(countries.find(country => country.id === value) as Country | undefined);
				props.field.onChange(e);
			}}
			label={props.field.value ? "" : "Country"}
		>
			{isLoading || countries.length === 0 ? (
				<MenuItem key={""} value={""} disabled>
					{isLoading ? "Loading..." : "Countries"}
				</MenuItem>
			) : null}
			{countries.map(country => (
				<MenuItem key={country.code} value={country.id}>
					{country.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export const Recipient = () => {
	const classes = useStyles();
	const { isLoggedIn } = useAppSelector(getFullState);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				Recipient
			</Typography>

			<Box mt={3}>
				<Box>
					<Grid container spacing={3}>
						{!isLoggedIn && (
							<>
								<Grid item xs={12}>
									<Field component={TextField} type="text" name="gifterName" placeholder="Gifter Name" fullWidth />
								</Grid>
								<Grid item xs={12}>
									<Field
										// label="Email"
										component={TextField}
										name="gifterEmail"
										type="email"
										placeholder="Gifter Email"
										fullWidth
									/>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<Field component={TextField} type="text" name="recipientName" placeholder="Recipient's Name" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field
								// label="Email"
								component={TextField}
								name="recipientEmail"
								type="email"
								placeholder="Recipient's Email"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								// hiddenLabel
								component={TextField}
								multiline
								name="message"
								placeholder="Enter Message"
								lines={4}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export const AgreeToTermsAndConditions = ({ classes, termValue, setFieldValue, changeHandler }) => (
	<Grid item xs={12}>
		<FormControlLabel
			control={
				<Checkbox
					checked={termValue}
					onChange={e => {
						setFieldValue("terms", !termValue);
						changeHandler(e);
					}}
					color="primary"
					name="terms"
				/>
			}
			label={
				<span className={classes.termsAndConditionsCheck}>
					I agree to the
					<span
						className={classes.termsLink}
						onClick={e => {
							e.preventDefault();
							window.open("https://study.remarnurse.com/vit/terms-conditions/", "_blank");
						}}
					>
						Terms & Conditions
					</span>
				</span>
			}
		/>
	</Grid>
);

interface AccountFormDetailsProps {
	title?: string;
	password?: boolean;
	phoneNumber?: string;
	showPasswordOption?: boolean;
	confirmPassword?: boolean;
	termValue?: boolean;
	showTermCheckBox?: boolean;
	disabledField?: boolean;
	trialSignUp?: boolean;
	setFieldValue?: (name, val) => void;
}

export const AccountFormDetails: FC<AccountFormDetailsProps> = ({
	title = "Account Details",
	termValue,
	phoneNumber,
	setFieldValue,
	showTermCheckBox = false,
	disabledField = false,
	password = true,
	showPasswordOption = false,
	trialSignUp = false,
	confirmPassword
}) => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const { validatePassword, showPasswordErrorBox, passwordErrorBox } = usePasswordErrorBox();
	// we might need this in future
	// useEffect(() => {
	// 	const elem = ref.current;
	// 	if (elem) {
	// 		 const i = elem.querySelector("input");
	// 		 i?.focus();
	// 	}
	// }, [ref?.current]);

	const [country, setCountry] = useState<PhoneInputCountry>("US");

	const isPhoneValid = useMemo(() => {
		return !isEmpty(phoneNumber) ? isValidPhoneNumber(phoneNumber!, country) : false;
	}, [country, phoneNumber]);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				{title}
			</Typography>

			<Box mt={3}>
				<Box>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Field component={TextField} label="First Name" name="firstName" placeholder="First Name" fullWidth />
						</Grid>
						<Grid item xs={12} md={6}>
							<Field label="Last Name" component={TextField} name="lastName" placeholder="Last Name" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field
								label="Email"
								component={TextField}
								name="email"
								type="email"
								placeholder="Email"
								fullWidth
								disabled={disabledField}
								inputProps={{ className: disabledField ? classes.disabledInput : "" }}
							/>
						</Grid>
						{trialSignUp && (
							<PhoneWrapper item xs={12}>
								<Field
									label="phoneNumber"
									name="phoneNumber"
									placeholder="+000 000 00"
									fullWidth
									error={!isPhoneValid}
									defaultCountry="US"
									initialValueFormat="national"
									addInternationalOption={false}
									onCountryChange={country => setCountry(country)}
									startAdornment={<span>+{country && getCountryCallingCode(country)}</span>}
									value={phoneNumber}
									onChange={v => setFieldValue("phoneNumber", v)}
									component={PhoneInput}
								/>
								{phoneNumber && !isPhoneValid && (
									<FormHelperText style={{ marginLeft: 16 }} error>
										{!isEmpty(phoneNumber)
											? isPhoneValid
												? undefined
												: "Invalid phone number"
											: "Phone number required"}
									</FormHelperText>
								)}
							</PhoneWrapper>
						)}
						{password && (
							<Grid item xs={12} style={{ position: "relative" }}>
								<Field
									label="Password"
									component={TextField}
									name="password"
									type={showPassword ? "text" : "password"}
									placeholder="Password"
									fullWidth
									InputProps={
										showPasswordOption
											? {
													endAdornment: (
														<SvgIcon
															fontSize="small"
															cursor="pointer"
															onClick={() => setShowPassword(prevState => !prevState)}
														>
															{showPassword ? <StyledVisibilityOutlined /> : <StyledVisibilityOffOutlined />}
														</SvgIcon>
													),
													disableUnderline: true
											  }
											: { disableUnderline: true }
									}
									validate={validatePassword}
								/>
								{showPasswordErrorBox && <PasswordMatchBox passwordErrorBox={passwordErrorBox} />}
							</Grid>
						)}
						{confirmPassword && (
							<Grid item xs={12}>
								<Field
									label="Confirm Password"
									component={TextField}
									name="confirmPassword"
									type="password"
									placeholder="Confirm Password"
									fullWidth
								/>
							</Grid>
						)}

						{showTermCheckBox && (
							<AgreeToTermsAndConditions classes={classes} termValue={termValue} setFieldValue={setFieldValue} />
						)}
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export const ShippingForm = ({ setTouchedField, handleChanged }) => {
	const classes = useStyles();

	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const elem = ref.current;
		if (elem) {
			const i = elem.querySelector("input");
			i?.focus();
		}
	}, [ref?.current]);

	const changeHandler = e => {
		setTouchedField(e.target.name, true, false);
		handleChanged && handleChanged(e);
	};

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				Shipping Details
			</Typography>

			<Box mt={3}>
				<Box>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Field
								inputRef={ref}
								hiddenLabel
								component={CountrySelect}
								name="countryId"
								fullWidth
								onChange={changeHandler}
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								hiddenLabel
								component={TextField}
								name="address1"
								placeholder="Address Line 1"
								fullWidth
								onChange={changeHandler}
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								hiddenLabel
								component={TextField}
								name="address2"
								placeholder="Apt #"
								fullWidth
								onChange={changeHandler}
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<Field
								hiddenLabel
								component={TextField}
								name="city"
								placeholder="City"
								fullWidth
								onChange={changeHandler}
							/>
						</Grid>
						<Grid item xs={6} md={3} className={classes.stateInput}>
							<Field
								hiddenLabel
								component={TextField}
								name="state"
								placeholder="State"
								fullWidth
								onChange={changeHandler}
							/>
						</Grid>
						<Grid item xs={6} md={4}>
							<Field
								hiddenLabel
								component={TextField}
								name="zip"
								inputProps={{ maxLength: 10 }}
								placeholder="Zip code"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!(event.key.length === 1 && validZipCodeCharacter(event.key))) {
										event.preventDefault();
									}
								}}
								fullWidth
								onChange={changeHandler}
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								hiddenLabel
								component={TextField}
								name="phoneNumber"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!validatePhoneNumber(event.key)) {
										event.preventDefault();
									}
								}}
								placeholder="Phone Number"
								fullWidth
								onChange={changeHandler}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

export const GuestShippingForm = () => {
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const elem = ref.current;
		if (elem) {
			const i = elem.querySelector("input");
			i?.focus();
		}
	}, [ref?.current]);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				{"Shipping Details"}
			</Typography>

			<Box mt={2}>
				<Box>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<Field
								inputRef={ref}
								component={TextField}
								label="First Name"
								type="text"
								name="firstName"
								placeholder="First Name"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field
								component={TextField}
								label="Last Name"
								type="text"
								name="lastName"
								placeholder="Last Name"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field label="Email" component={TextField} name="email" type="email" placeholder="Email" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field hiddenLabel component={CountrySelect} name="countryId" fullWidth />
						</Grid>

						<Grid item xs={12}>
							<Field hiddenLabel component={TextField} name="address1" placeholder="Address Line 1" fullWidth />
						</Grid>
						<Grid item xs={12}>
							<Field hiddenLabel component={TextField} name="address2" placeholder="Apt #" fullWidth />
						</Grid>
						<Grid item xs={12} md={5}>
							<Field hiddenLabel component={TextField} name="city" placeholder="City" fullWidth />
						</Grid>
						<Grid item xs={6} md={3}>
							<Field hiddenLabel component={TextField} name="state" placeholder="State" fullWidth />
						</Grid>
						<Grid item xs={6} md={4}>
							<Field
								hiddenLabel
								component={TextField}
								name="zipCode"
								inputProps={{ maxLength: 10 }}
								placeholder="Zip code"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!(event.key.length === 1 && validZipCodeCharacter(event.key))) {
										event.preventDefault();
									}
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								hiddenLabel
								component={TextField}
								name="phoneNumber"
								onKeyPress={(event: React.KeyboardEvent) => {
									if (!validatePhoneNumber(event.key)) {
										event.preventDefault();
									}
								}}
								placeholder="Phone Number"
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};

interface PaymentFormProps {
	paymentSource?: {
		brand: string;
		last4: string;
		exp_month: number;
		exp_year: number;
	};
	setFieldValue?: (name, val) => void;
	touched?: FormikTouched<Record<string, boolean>>;
	setTouched?: (name) => void;
	setTouchedField?: (name, value, shouldValidate) => void;
	handleChanged?: (e) => void;
	termValue?: boolean;
	showTerms?: boolean;
	startDate?: Date;
	setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
	paymentType?: string;
	setPaymentType?: React.Dispatch<React.SetStateAction<IPaymentType>>;
	totalAmount?: number;
}

export const PaymentForm: FC<PaymentFormProps> = ({
	paymentSource,
	setFieldValue,
	termValue,
	setTouchedField,
	showTerms = false,
	handleChanged,
	startDate,
	setStartDate,
	setPaymentType,
	paymentType,
	totalAmount
}) => {
	const classes = useStyles();
	const theme = useTheme<Theme>();
	const elements = useElements();
	const [newCard, setNewCard] = useState(isEmpty(paymentSource));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [openPicker, setOpenPicker] = useState(false);
	const {
		countries,
		selectedCountryId,
		subscription: { isTrial },
		inactiveSubscription: { isRenewEnabled }
	} = useAppSelector(getFullState);

	const changeHandler = e => {
		setTouchedField && setTouchedField(e.target.name, true, false);
		handleChanged && handleChanged(e);
	};
	const handleDateChange = date => {
		setStartDate && setStartDate!(date);
	};

	const countryCode = useMemo(
		() => selectedCountryId && getCountryCode(countries, selectedCountryId!),
		[countries, selectedCountryId]
	);

	const allowAffirm = useMemo(() => {
		if (!totalAmount) return false;
		const totalRoundedAmountInCents = Math.round(totalAmount * 100);
		const supportedCountries = ["US", "CA"];
		return (
			(!isTrial || (isTrial && !isRenewEnabled)) &&
			supportedCountries.some(c => c === countryCode) &&
			totalRoundedAmountInCents > GLOBAL_CONSTANTS.STRIPE_AFFIRM_AMOUNT_LIMIT
		);
	}, [paymentType, isTrial, isRenewEnabled, totalAmount, countryCode]);

	useEffect(() => {
		if (!totalAmount) return;
		// update stripe elements to allow other payment options
		const totalRoundedAmountInCents = Math.round(totalAmount * 100);
		const canUpdateElements = totalRoundedAmountInCents > 0;

		const paymentMethodsAllowed = allowAffirm ? [IPaymentType.CARD, IPaymentType.AFFIRM] : [IPaymentType.CARD];
		if (canUpdateElements) {
			elements &&
				elements.update({
					appearance:
						theme.palette.type === "dark"
							? {
									theme: "night",
									rules: {
										".Input:focus": {
											border: `1px solid ${theme.palette.colors.basic[300]}`,
											boxShadow: "none"
										},
										".AccordionItem--selected": {
											color: theme.palette.colors.primary[1000]
										},
										".PaymentMethodMessaging": {
											color: theme.palette.colors.basic[300]
										}
									}
							  }
							: {},
					amount: totalRoundedAmountInCents,
					payment_method_types: paymentMethodsAllowed
				});
		}
	}, [allowAffirm, totalAmount, countryCode]);

	return (
		<Box>
			<Typography variant="h1" className={classes.title}>
				Payment
			</Typography>

			<Box mt={3}>
				<Box>
					<Grid container spacing={3}>
						{newCard && (
							<>
								<Grid item xs={12}>
									<Box mb={-2} mt={-1}>
										<Typography className={classes.formSubtitle}>Cardholder’s Name</Typography>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Field
										hiddenLabel
										component={TextField}
										name="nameOnCard"
										placeholder="Full Name"
										fullWidth
										onChange={changeHandler}
									/>
								</Grid>
							</>
						)}

						<Grid item xs={12}>
							<Box mb={-2} mt={-1}>
								<Typography className={classes.formSubtitle}>Billing Details</Typography>
							</Box>
						</Grid>
						{newCard && (
							<PaymentWrapper item xs={12}>
								<Box py={2} borderRadius={5}>
									<PaymentElement
										options={{
											layout: "accordion"
										}}
										onChange={event => {
											setPaymentType && setPaymentType(event.value.type as IPaymentType);
											if (setFieldValue) {
												const { complete } = event;
												setFieldValue("validCardDetails", complete);
											}
											setTouchedField && setTouchedField("validCardDetails", true, false);
										}}
									/>
									{allowAffirm && (
										<PaymentMethodMessagingElementWrapper>
											<PaymentMethodMessagingElement
												options={{
													amount: (totalAmount || 0) * 100,
													currency: "USD",
													countryCode: countryCode as "US" | "CA",
													paymentMethodTypes: [IPaymentType.AFFIRM]
												}}
											/>
										</PaymentMethodMessagingElementWrapper>
									)}
								</Box>
							</PaymentWrapper>
						)}
						{paymentSource && !isEmpty(paymentSource!) && !newCard && (
							<Grid item xs={12}>
								<Box bgcolor="#2a2e37" mt={2} p={2} borderRadius="4px">
									<Box display="flex" mb={2} alignItems="center">
										{getCardIcon(paymentSource.brand)}
										<Box ml={2}>
											<Box display="flex" flexWrap="wrap">
												<CardName>{paymentSource.brand}</CardName>
												<CardName>{`.... ${paymentSource.last4}`}</CardName>
											</Box>
											<PaymentExpiredText>{`Expiration date: ${paymentSource.exp_month}/${paymentSource.exp_year}`}</PaymentExpiredText>
										</Box>
									</Box>
									<Button color="basic" onClick={() => setNewCard(true)}>
										<ManageText> Change Card </ManageText>
									</Button>
								</Box>
							</Grid>
						)}
						{showTerms && (
							<AgreeToTermsAndConditions
								classes={classes}
								termValue={termValue}
								setFieldValue={setFieldValue}
								changeHandler={changeHandler}
							/>
						)}
						{isMobile &&
							startDate &&
							(openPicker ? (
								<Box className={classes.summaryRoot} style={{ padding: "15px 10px" }}>
									<Box>
										<Box mb={4} display="flex" justifyContent="space-between">
											<Typography className={classes.changeTheStartDate}>Change The Start Date</Typography>
											<Box display="flex" alignItems="center" justifyContent="flex-end">
												<IconButton onClick={() => setOpenPicker(false)} className={classes.closeIcon}>
													<CloseIcon color="disabled" />
												</IconButton>
											</Box>
										</Box>
										<div className={classes.datepickerInputContainer}>
											<DatePicker
												autoOk
												open={false}
												disablePast
												disableToolbar
												orientation="portrait"
												variant="static"
												openTo="date"
												maxDate={addMonths(new Date(), 3)}
												value={startDate}
												onChange={handleDateChange}
											/>
										</div>
										<Box display="flex" justifyContent="flex-end" mt={2}>
											<Button variant={"filled"} color={"primary"} onClick={() => setOpenPicker(false)}>
												Done
											</Button>
										</Box>
									</Box>
								</Box>
							) : (
								<Box style={{ padding: "16px 13px" }} width={"100%"}>
									<Box mb={1}>
										<Typography className={classes.formSubtitle}>
											Your subscription will start on {format(startDate as Date, "MMM dd, yyyy.")}
										</Typography>
									</Box>
									<Button
										fullWidth
										variant={"outlined"}
										color={"basic"}
										startIcon={<StyledCalenderIcon />}
										onClick={() => setOpenPicker(true)}
									>
										Change your start date
									</Button>
								</Box>
							))}
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};
