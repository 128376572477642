import React, { memo, useMemo } from "react";

import { Box, MenuItem, useMediaQuery, useTheme } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { Avatar } from "./components";
import { Actions, BannerContentWrapper, Container, Menu, Text } from "./styles";

import { UserInfo } from "../../../../models";
import { getFullName } from "../../../../utils/auth";
import { getBadgeTooltip } from "../../../../utils/myAccountUtils";

type ActionType = { name: string; onClick: () => void };

type Props = {
	userInfo: Pick<UserInfo, "firstName" | "lastName" | "profileImageUrl" | "badges"> | null;
	accountPath?: string;
	onLogOut: () => void;
	banner?: JSX.Element;
	searchbar?: JSX.Element;
	topMenuItems?: React.ReactNode;
	dropDownMenuItems?: ActionType[];
};
const TopNav = ({
	userInfo,
	accountPath,
	onLogOut,
	dropDownMenuItems = [],
	topMenuItems,
	banner,
	searchbar
}: Props) => {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const baseMenuItems: ActionType[] = useMemo(
		() => [
			{
				name: "My Account",
				onClick: () => (accountPath ? history.push(accountPath) : {})
			},
			{
				name: "Logout",
				onClick: onLogOut
			},
			...dropDownMenuItems
		],
		[accountPath, dropDownMenuItems, history, onLogOut]
	);

	const { badges } = userInfo || {};
	const { createdAt, typeId } = (badges?.length && badges[0]) || {};
	const toolTipText = typeId ? getBadgeTooltip(typeId, createdAt) : "";

	// TODO: remove Margintop and  put Margin-Right = 32 on header when showBanner is false.
	return (
		<Container isBanner={false} isMobile={isMobile}>
			<BannerContentWrapper>
				<Box className={"banner"}>{banner}</Box>
				<Box className={"navbar"}>
					<Box width={"70%"}>{searchbar && searchbar}</Box>
					<Actions>
						{topMenuItems}
						<Text>{getFullName(userInfo)}</Text>
						<Avatar
							userInfo={userInfo}
							setAnchorEl={setAnchorEl}
							badgeData={typeId ? { type: typeId, description: toolTipText } : undefined}
						/>
						<Menu
							id="user-menu"
							anchorEl={anchorEl}
							style={{ top: "40px" }}
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}
						>
							{baseMenuItems.map(({ name, onClick }) => (
								<MenuItem key={name} onClick={onClick}>
									{name}
								</MenuItem>
							))}
						</Menu>
					</Actions>
				</Box>
			</BannerContentWrapper>
		</Container>
	);
};

export default memo(TopNav);
