import { buildRouteTree } from "@remar/shared/dist/utils/routeUtils";

import { GLOBAL_CONSTANTS } from "../constants";

export enum LessonTypes {
	VideoOnly = 1, // eslint-disable-line no-unused-vars
	Interactive = 2, // eslint-disable-line no-unused-vars
	TestOnly = 3 // eslint-disable-line no-unused-vars
}

export enum CancellationActions {
	PrimaryOption = "primaryOption",
	Option = "option",
	Cancel = "cancel",
	Redirect = "redirect",
	ExitAfterDiscount = "ExitAfterDiscount"
}

export const JOBS_LINK = GLOBAL_CONSTANTS.JOBS_LINK;

export const routes = buildRouteTree({
	billings: null,
	course: null,
	fileVault: null,
	forgotPassword: null,
	create_password: null,
	email_verification: null,
	help: null,
	lesson: null,
	myAccount: null,
	notes: null,
	email_invitation: null,
	tests: null,
	test: null,
	questionBank: null,
	settings: null,
	signIn: null,
	signUp: null,
	assets: null,
	maintenance: null,
	"institution-open-signup": null,
	shop: {
		books: null,
		gifts: null
	},
	search: null
});
