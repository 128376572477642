import React, { useState } from "react";

import { IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { useHistory } from "react-router-dom";

const SearchBar = () => {
	const params = useSearchParams();
	const text = params.get("text");
	const [searchText, setSearchText] = useState(text || "");
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const history = useHistory();

	const handleSearch = () => {
		history.push(`/search?text=${searchText}`);
	};

	return (
		<TextField
			fullWidth
			hiddenLabel
			InputProps={{
				style: {
					color: theme.palette.text.primary,
					background: theme.palette.background.default,
					height: 45,
					width: isMobile ? "100%" : "60%"
				},
				disableUnderline: true,
				startAdornment: (
					<InputAdornment position="start">
						<IconButton size="small">
							<SearchIcon style={{ color: "hsl(223,10%,58%)" }} height={20} width={20} />
						</IconButton>
					</InputAdornment>
				)
			}}
			color="primary"
			onChange={(event: React.ChangeEvent<{ value: string }>) => {
				setSearchText(event.target.value);
			}}
			onKeyDown={(event: React.KeyboardEvent) => {
				if (event.key === "Enter" && !event.shiftKey) {
					event.preventDefault();
					handleSearch();
				}
			}}
			variant="filled"
			size="small"
			placeholder="Search lectures"
			value={searchText}
		/>
	);
};

export default SearchBar;
