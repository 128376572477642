import React, { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { AnnouncementWrapper } from "./styles";

import dummyImage from "../../assets/images/forgot-password.png";

import { ButtonActionEnum, Coupon, ISocketNotifications, NotificationsModel } from "../../models";
import Button from "../Button";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};

type AnnouncementItemProps = Partial<NotificationsModel> & {
	applyCoupon: (c: Coupon) => void;
	dismiss: (id: number) => void;
};
const AnnouncementItem = ({
	id,
	body,
	title,
	data,
	coupon,
	mainImageKey,
	mainImageUrl,
	applyCoupon,
	dismiss
}: AnnouncementItemProps) => {
	const { primaryButton } = data || {};

	const action = useMemo(() => {
		switch (primaryButton?.action) {
			case ButtonActionEnum.ApplyCoupon:
				return () => applyCoupon({ ...coupon!, dismissId: id });
			case ButtonActionEnum.Dismiss:
				return () => dismiss(id!);
			case ButtonActionEnum.OpenLink:
				return () => window.open(primaryButton.url, "_blank");
			default:
				return () => {};
		}
	}, [applyCoupon, coupon, dismiss, id, primaryButton]);

	return (
		<Box display={"flex"} flexDirection={"column"} className={"carousel-item"}>
			<img src={mainImageKey ? mainImageUrl : dummyImage} alt="image" height={240} width={"100%"} />
			<Box mt={2} className={"body"}>
				<Typography>{title}</Typography>
				<Typography>{body}</Typography>
			</Box>
			<Box p={1} className={"action"}>
				<Button variant={"filled"} color={"primary"} onClick={action}>
					{primaryButton?.text}
				</Button>
			</Box>
		</Box>
	);
};

type AnnouncementProps = {
	announcements?: ISocketNotifications["items"];
	applyCoupon: (c: Coupon) => void;
	dismiss: (id: number) => void;
};
const Announcement = ({ announcements, applyCoupon, dismiss }: AnnouncementProps) => (
	<AnnouncementWrapper>
		<Carousel
			swipeable={false}
			draggable={false}
			showDots={announcements!.length > 1}
			autoPlay={false}
			arrows
			responsive={responsive}
		>
			{announcements?.map(n => {
				const { notification } = n as { notification: NotificationsModel };
				return <AnnouncementItem key={notification.id} {...{ ...notification, applyCoupon, dismiss }} />;
			})}
		</Carousel>
	</AnnouncementWrapper>
);

export default Announcement;
