import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CourseChapterSectionLesson } from "@remar/shared/dist/models";
import { RootState } from "store";

import { lessonsService } from "../../services";

export interface searchState {
	isLoading: boolean;
	searchResult: CourseChapterSectionLesson[];
}

const initialState: searchState = {
	isLoading: false,
	searchResult: []
};

export const fetchSearchResult = createAsyncThunk(
	"search/fetchSearchResult",
	async ({ text }: { text: string }, { rejectWithValue }) => {
		return lessonsService.getLessons(text).catch(err => rejectWithValue(err.message));
	}
);

export const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(fetchSearchResult.pending, state => {
				state.isLoading = true;
			})
			.addCase(fetchSearchResult.fulfilled, (state, action) => {
				state.isLoading = false;
				state.searchResult = action.payload as CourseChapterSectionLesson[];
			})
			.addCase(fetchSearchResult.rejected, state => {
				state.isLoading = false;
			})
});

export const getFullSearchState = (state: RootState): searchState => state.search;

export const {} = searchSlice.actions;

export default searchSlice.reducer;
